<template>
  <div class="app flex-row align-items-center" id="login-page">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <h3 class="text-center">Khôi phục mật khẩu</h3>
                <b-alert
                  variant="danger"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlert"
                  @dismissed="mData.dataDefault.showDismissibleAlert=false"
                >{{mData.dataDefault.error}}</b-alert>
                <b-alert
                  variant="success"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlertSuccess"
                  @dismissed="mData.dataDefault.showDismissibleAlertSuccess=false"
                >{{mData.dataDefault.success}}</b-alert>

                <el-form
                  :model="mData.dataForm"
                  :rules="mData.validForm"
                  ref="myForm"
                  label-width="100%"
                  label-position="top"
                  @submit.native.prevent
                >
                  <!-- <el-form-item label="Tài khoản" prop="account">
                    <el-input
                      prefix-icon="el-icon-user"
                      v-model.trim="mData.dataForm.account"
                      clearable
                      disabled
                    ></el-input>
                  </el-form-item>-->

                  <el-form-item label="Nhập mã khôi phục" prop="code">
                    <el-input
                      prefix-icon="el-icon-key"
                      v-model.trim="mData.dataForm.code"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Nhập mật khẩu" prop="pass">
                    <el-input
                      prefix-icon="el-icon-lock"
                      v-model.trim="mData.dataForm.pass"
                      show-password
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Nhập lại mật khẩu" prop="repass">
                    <el-input
                      prefix-icon="el-icon-lock"
                      v-model.trim="mData.dataForm.repass"
                      show-password
                    ></el-input>
                  </el-form-item>

                  <el-form-item class="text-center">
                    <el-button
                      type="primary"
                      @click="fn_submitForm()"
                      v-ladda
                      native-type="submit"
                    >Xác nhận</el-button>
                  </el-form-item>
                </el-form>
                <b-col md="12" class="text-center">
                  <b-link to="/auth/login">Quay lại trang đăng nhập</b-link>
                </b-col>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AuthService from "../../service/AuthService";
import LanguageService from "../../service/LanguageService";
import ValidService from "../../service/ValidService";
import MethodService from "../../service/MethodService";
export default {
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        dataDefault: {
          error: "",
          showDismissibleAlert: false,
          success: "",
          showDismissibleAlertSuccess: false
        },
        dataForm: {
          account: "",
          code: "",
          pass: "",
          repass: ""
        },
        validForm: {
          account: [ValidService.required],
          code: [ValidService.required],
          pass: [ValidService.required, ValidService.checkLength(6)],
          repass: [ValidService.required, ValidService.checkLength(6)]
        }
      }
    };
  },
  methods: {
    fn_submitForm() {
      this.$refs["myForm"].validate(valid => {
        if (valid) {
          if (this.mData.dataForm.pass != this.mData.dataForm.repass) {
            this.mData.dataDefault.showDismissibleAlert = true;
            this.mData.dataDefault.error =
              "Mật khẩu và nhập lại mật khẩu không khớp";
            MethodService.stopLadda();
            return;
          }
          AuthService.resetPassword(
            this.mData.dataForm.account,
            this.mData.dataForm.code,
            this.mData.dataForm.repass
          )
            .then(res => {
              this.$message({
                showClose: true,
                message: "Đăng nhập bằng mật khẩu mới thành công",
                type: "success",
                duration: 6000 //8s
              });
              AuthService.login(
                this.mData.dataForm.account,
                this.mData.dataForm.repass
              )
                .then(res => {
                  this.$router.push({ name: "Admin-Home" });
                })
                .catch(error => {
                  this.mData.dataDefault.showDismissibleAlert = true;
                  this.mData.dataDefault.error = "Đăng nhập không thành công";
                });
            })
            .catch(error => {
              this.mData.dataDefault.showDismissibleAlert = true;
              this.mData.dataDefault.error = "Khôi phục không thành công";
            });
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
    }
  },
  mounted: function() {
    if (this.$router.currentRoute.query.account) {
      this.mData.dataForm.account = this.$router.currentRoute.query.account;
      this.mData.dataDefault.showDismissibleAlertSuccess = true;
      this.mData.dataDefault.success =
        "Mã code đã được gửi tới " +
        this.mData.dataForm.account +
        " thành công, kiểm tra email để lấy mã code";
    }
  }
};
</script>
<style>
</style>
